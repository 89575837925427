import "./styles.scss"

import React from "react"

import ApartmentRowItem from "components/ApartmentRowItem"

const ApartmentsTable = ({ data, showInvestment, targetBlank }) => {
  return (
    <div className="investment-apartments-listing">
      <div className="investment-apartments-listing__inner">
        <div className="investment-apartments-listing__table">
          <div className="investment-apartments-listing__table-header">
            <div className="row">
              {showInvestment && <div className="col">Investycja</div>}
              <div className="col">
                <span className="desktop">Nr apartamentu</span>
                <span className="mobile">Nr</span>
              </div>
              <div className="col">
                Metraż (m<sup>2</sup>)
              </div>
              <div className="col">Pokoje</div>
              <div className="col">Piętro</div>
              <div className="col">Status</div>
              <div className="col">Karta lokalu</div>
              <div className="col-2"></div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
        {data?.map((item, index) => (
          <ApartmentRowItem
            item={item}
            key={index}
            showInvestment={showInvestment}
            targetBlank={targetBlank}
          />
        ))}
      </div>
    </div>
  )
}

export default ApartmentsTable
