/* eslint-disable jsx-a11y/label-has-associated-control */

import "./styles.scss"

import React, { useState } from "react"
import ReactSelect from "react-select"

import Button from "components/Button"
import Input from "components/Input"
import Select from "components/Select"

import InvestmentTile from "components/InvestmentTile"
import InvestmentsMap from "components/InvestmentsMap"
import ApartmentsTable from "components/ApartmentsTable"

import { useInvestments } from "context/investments"
import { useApartments } from "context/apartments"

const selectStyles = {
  input: provided => ({
    ...provided,
    color: "#000",
  }),
  container: provided => ({
    ...provided,
    width: "100%",
    background: "transparent",
    backgroundColor: "transparent",
    outline: "none",
    border: "1px solid #c9c9c9",
    borderRadius: 0,
    padding: "6px 35px 6px 6px",
    fontWeight: 300,
    color: "#000",
  }),
  control: provided => ({
    ...provided,
    background: "transparent",
    border: "none",
    outline: "none",
    boxShadow: "none",
    color: "#000",
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: "#fff",
    right: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#000",
    backgroundColor: state.isSelected ? "#f4f4f4" : "transparent",
    "&:hover": {
      backgroundColor: "#f4f4f4",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorContainer: provided => ({
    ...provided,
    display: "none",
    color: "#000",
  }),
  placeholder: provided => ({
    ...provided,
    color: "#000",
  }),
  singleValue: provided => ({
    ...provided,
    color: "#000",
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: "none",
    color: "#000",
  }),
  indicatorsContainer: provided => ({
    ...provided,
    display: "none",
  }),
}

const Content = () => {
  const isBrowser = typeof window !== "undefined"
  const queryParameters = new URLSearchParams(
    isBrowser && window.location.search
  )

  const { investments } = useInvestments()
  const { apartments } = useApartments()
  const { apartmentsStandalone } = useApartments()
  const allApartments = [...apartments, ...apartmentsStandalone]

  const [type, setType] = useState("investments")
  const [view, setView] = useState("list")
  const [sort, setSort] = useState(null)

  const param_area_start = queryParameters.get("area_start")
  const param_area_end = queryParameters.get("area_end")
  const param_rooms_start = queryParameters.get("rooms_start")
  const param_rooms_end = queryParameters.get("rooms_end")
  const param_floors_start = queryParameters.get("floors_start")
  const param_floors_end = queryParameters.get("floors_end")
  const param_status = queryParameters.get("status")
  const param_region = queryParameters.get("region")
  const price_start = queryParameters.get("price_start")
  const price_end = queryParameters.get("price_end")
  const param_city = queryParameters.get("city")

  const [filter, setFilter] = useState({
    areaStart: param_area_start ? param_area_start : "",
    areaEnd: param_area_end ? param_area_end : "",
    roomsStart: param_rooms_start ? param_rooms_start : "",
    roomsEnd: param_rooms_end ? param_rooms_end : "",
    floorsStart: param_floors_start ? param_floors_start : "",
    floorsEnd: param_floors_end ? param_floors_end : "",
    status: param_status ? param_status : "sell",
    region: param_region ? param_region : "",
    priceStart: price_start ? price_start : "",
    priceEnd: price_end ? price_end : "",
    city: param_city ? [`${param_city}`] : "",
  })

  let filteredApartments = allApartments
  let filteredInvestments = investments

  function inRange(x, min, max) {
    return (x - min) * (x - max) <= 0
  }

  const apartmentsFiltering = () => {
    filteredApartments = allApartments
      ?.filter(item => {
        const floor = parseInt(
          item.acfApartment.floor ? item.acfApartment.floor : 0
        )

        if (
          item.acfApartment.area >= (filter.areaStart || 1) &&
          item.acfApartment.area <= (filter.areaEnd || 200) &&
          item.acfApartment.roomsnumber >= filter.roomsStart &&
          item.acfApartment.roomsnumber <= (filter.roomsEnd || 10) &&
          item.acfApartment.price >= (parseInt(filter.priceStart) || 1) &&
          item.acfApartment.price <= (parseInt(filter.priceEnd) || 10000000) &&
          floor >= filter.floorsStart &&
          floor <= (filter.floorsEnd || 10) &&
          (filter?.status?.includes(item?.acfApartment?.status) ||
            filter?.status?.length === 0) &&
          (filter?.region?.includes(
            item?.acfApartment?.investment
              ? item?.acfApartment?.investment[0]?.acfInvestment?.region
              : null
          ) ||
            filter?.region?.length === 0)
        ) {
          return item
        }
        return false
      })
      .sort((a, b) =>
        sort === "area_asc"
          ? a.acfApartment.area - b.acfApartment.area
          : sort === "area_desc"
          ? b.acfApartment.area - a.acfApartment.area
          : sort === "rooms_asc"
          ? a.acfApartment.roomsnumber - b.acfApartment.roomsnumber
          : sort === "rooms_desc"
          ? b.acfApartment.roomsnumber - a.acfApartment.roomsnumber
          : sort === "floor_asc"
          ? a.acfApartment.floor - b.acfApartment.floor
          : sort === "floor_desc"
          ? b.acfApartment.floor - a.acfApartment.floor
          : a.title - b.title
      )
  }

  const uniqueCities = [
    ...new Set(
      investments.map(item => item.acfInvestment.city).filter(item => item)
    ),
  ]

  const investmentsFiltering = () => {
    if (!!filter?.region) {
      filteredInvestments = filteredInvestments?.filter(
        item => item?.acfInvestment?.region === filter?.region
      )
    }

    if (!!filter?.status) {
      filteredInvestments = filteredInvestments?.filter(
        item => item?.acfInvestment?.status === filter?.status
      )
    }

    if (!!filter?.city) {
      filteredInvestments = filteredInvestments?.filter(item =>
        filter?.city.includes(item?.acfInvestment?.city)
      )
    }

    if (!!filter?.areaStart) {
      filteredInvestments = filteredInvestments?.filter(item =>
        inRange(
          parseInt(filter.areaStart),
          parseInt(item.acfInvestment.areaNumberMin),
          parseInt(item.acfInvestment.areaNumberMax)
        )
      )
    }

    if (!!filter?.areaEnd) {
      filteredInvestments = filteredInvestments?.filter(item =>
        inRange(
          parseInt(filter.areaEnd),
          parseInt(item.acfInvestment.areaNumberMin),
          parseInt(item.acfInvestment.areaNumberMax)
        )
      )
    }

    if (!!filter?.priceStart) {
      filteredInvestments = filteredInvestments?.filter(item =>
        inRange(
          parseInt(filter.priceStart),
          parseInt(item.acfInvestment.apartmenPriceMin),
          parseInt(item.acfInvestment.apartmenPriceMax)
        )
      )
    }

    if (!!filter?.priceEnd) {
      filteredInvestments = filteredInvestments?.filter(item =>
        inRange(
          parseInt(filter.priceEnd),
          parseInt(item.acfInvestment.apartmenPriceMin),
          parseInt(item.acfInvestment.apartmenPriceMax)
        )
      )
    }

    if (!!filter?.roomsStart) {
      filteredInvestments = filteredInvestments?.filter(item =>
        inRange(
          parseInt(filter.roomsStart),
          parseInt(item.acfInvestment.roomsNumberMin),
          parseInt(item.acfInvestment.roomsNumberMax)
        )
      )
    }

    if (!!filter?.roomsEnd) {
      filteredInvestments = filteredInvestments?.filter(item =>
        inRange(
          parseInt(filter.roomsEnd),
          parseInt(item.acfInvestment.roomsNumberMin),
          parseInt(item.acfInvestment.roomsNumberMax)
        )
      )
    }

    if (!!filter?.floorsStart) {
      filteredInvestments = filteredInvestments?.filter(item =>
        inRange(
          parseInt(filter.floorsStart),
          parseInt(
            item.acfInvestment.floorsNumberMin === null
              ? 0
              : item.acfInvestment.floorsNumberMin
          ),
          parseInt(item.acfInvestment.floorsNumberMax)
        )
      )
    }

    if (!!filter?.floorsEnd) {
      filteredInvestments = filteredInvestments?.filter(item =>
        inRange(
          parseInt(filter.floorsEnd),
          parseInt(
            item.acfInvestment.floorsNumberMin === null
              ? 0
              : item.acfInvestment.floorsNumberMin
          ),
          parseInt(item.acfInvestment.floorsNumberMax)
        )
      )
    }
  }

  apartmentsFiltering()
  investmentsFiltering()

  return (
    <section className="search-content">
      <div className="container-fluid">
        <div className="search-content__region">
          <Button
            className={filter.region !== "" ? "button--bordered" : ""}
            onClick={() =>
              setFilter({
                ...filter,
                region: "",
              })
            }
          >
            Wszystkie
          </Button>
          <Button
            className={filter.region !== "sea" ? "button--bordered" : ""}
            onClick={() =>
              setFilter({
                ...filter,
                region: "sea",
              })
            }
          >
            Morze
          </Button>
          <Button
            className={filter.region !== "mountains" ? "button--bordered" : ""}
            onClick={() =>
              setFilter({
                ...filter,
                region: "mountains",
              })
            }
          >
            Góry
          </Button>
          <Button
            className={filter.region !== "lakes" ? "button--bordered" : ""}
            onClick={() =>
              setFilter({
                ...filter,
                region: "lakes",
              })
            }
          >
            Jezioro
          </Button>
          <Button
            className={filter.region !== "city" ? "button--bordered" : ""}
            onClick={() =>
              setFilter({
                ...filter,
                region: "city",
              })
            }
          >
            Miasto
          </Button>
          <Button
            className={filter.region !== "abroad" ? "button--bordered" : ""}
            onClick={() =>
              setFilter({
                ...filter,
                region: "abroad",
              })
            }
          >
            Zagraniczne
          </Button>
        </div>

        <div className="search-content__search">
          <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-6">
              <Select
                label="Typ"
                value={type}
                onChange={e => {
                  setType(e.target.value)
                  e.target.value === "investments"
                    ? setFilter({
                        ...filter,
                        status: "sell",
                      })
                    : setFilter({
                        ...filter,
                        status: "available",
                      })
                }}
                options={[
                  { label: "Inwestycje", value: "investments" },
                  { label: "Apartamenty", value: "apartments" },
                ]}
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              {type === "investments" ? (
                <Select
                  label="Status inwestycji"
                  value={filter.status}
                  options={[
                    { label: "Wszystkie", value: [] },
                    { label: "W sprzedaży", value: "sell" },
                    { label: "Zakończone", value: "done" },
                  ]}
                  onChange={e =>
                    setFilter({
                      ...filter,
                      status: e.target.value,
                    })
                  }
                />
              ) : (
                <Select
                  className="no-m"
                  label="Status"
                  value={filter.status}
                  options={[
                    { label: "Wszystkie", value: [] },
                    { label: "Wolne", value: "available" },
                    { label: "Zarezerwowane", value: "reserved" },
                    { label: "Sprzedane", value: "sold" },
                  ]}
                  onChange={e =>
                    setFilter({
                      ...filter,
                      status: e.target.value,
                    })
                  }
                />
              )}
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <Input
                type="number"
                className="input--bordered"
                label="Metraż od"
                placeholder="20 m2"
                value={filter.areaStart}
                onChange={e => {
                  setFilter({
                    ...filter,
                    areaStart: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <Input
                type="number"
                className="input--bordered"
                label="Metraż do"
                placeholder="60 m2"
                value={filter.areaEnd}
                onChange={e =>
                  setFilter({
                    ...filter,
                    areaEnd: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <Input
                type="number"
                className="input--bordered"
                label="Cena netto od"
                placeholder="389 000 zł"
                value={filter.priceStart}
                onChange={e =>
                  setFilter({
                    ...filter,
                    priceStart: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <Input
                type="number"
                className="input--bordered"
                label="Cena netto do"
                placeholder="589 000 zł"
                value={filter.priceEnd}
                onChange={e =>
                  setFilter({
                    ...filter,
                    priceEnd: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <Input
                type="number"
                className="input--bordered"
                label="Liczba pokoi od"
                placeholder="2"
                value={filter.roomsStart}
                onChange={e => {
                  setFilter({
                    ...filter,
                    roomsStart: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <Input
                type="number"
                className="input--bordered"
                label="Liczba pokoi do"
                placeholder="4"
                value={filter.roomsEnd}
                onChange={e =>
                  setFilter({
                    ...filter,
                    roomsEnd: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <Input
                type="number"
                className="input--bordered"
                label="Piętro od"
                placeholder="2"
                value={filter.floorsStart}
                onChange={e => {
                  setFilter({
                    ...filter,
                    floorsStart: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <Input
                type="number"
                className="input--bordered"
                label="Piętro do"
                placeholder="4"
                value={filter.floorsEnd}
                onChange={e =>
                  setFilter({
                    ...filter,
                    floorsEnd: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="select">
                <label className="select__label">Miasto</label>
                <ReactSelect
                  placeholder="Wszystkie"
                  styles={selectStyles}
                  defaultValue={
                    param_city
                      ? { value: filter.city, label: filter.city }
                      : filter.city
                  }
                  options={uniqueCities.map(option => ({
                    value: option,
                    label: option,
                  }))}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={e =>
                    e.length > 0
                      ? setFilter({
                          ...filter,
                          city: e.map(item => item.value),
                        })
                      : setFilter({
                          ...filter,
                          city: "",
                        })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="search-content__filters">
          <small>Wyniki wyszukiwania:</small>
          <div className="search-content__filters-wrapper">
            <div className="search-content__filters-type"></div>
            <div className="search-content__filters-view">
              {type === "apartments" ? (
                <Select
                  className="no-m"
                  label="Sortuj:"
                  onChange={e => setSort(e.target.value)}
                  options={[
                    { label: "Metraż: od najmniejszego", value: "area_asc" },
                    { label: "Metraż: od największego", value: "area_desc" },
                    {
                      label: "Pokoje: od największej ilości",
                      value: "rooms_desc",
                    },
                    {
                      label: "Pokoje: od najmniejszej ilości",
                      value: "rooms_asc",
                    },
                    { label: "Piętro: od najwyższego", value: "floor_desc" },
                    { label: "Piętro: od najniższego", value: "floor_asc" },
                  ]}
                />
              ) : (
                <>
                  <Button
                    className={view !== "list" ? "button--bordered" : ""}
                    onClick={() => setView("list")}
                  >
                    Widok listy
                  </Button>
                  <Button
                    className={view !== "map" ? "button--bordered" : ""}
                    onClick={() => setView("map")}
                  >
                    Widok mapy
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="search-content__content">
          {type === "investments" && view === "map" && (
            <InvestmentsMap data={filteredInvestments} />
          )}

          {type === "investments" &&
            view === "list" &&
            filteredInvestments?.map((item, index) => (
              <InvestmentTile item={item} index={index} targetBlank />
            ))}

          {type === "apartments" && (
            <ApartmentsTable
              data={filteredApartments}
              showInvestment
              targetBlank
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default Content
