import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/search"

const Search = () => {
  const title = "Wyszukiwarka"

  return (
    <Layout
      seo={{
        title: title,
        description:
          "Wyszukiwarka apartamentów premium inwestycyjnych, mieszkań, domów. Wszystkie oferty Premium w jednym miejscu.",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
    </Layout>
  )
}

export default Search
